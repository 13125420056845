var countDownDate = new Date(gon.expected_end_time).getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();

  // Find the distance between now and the count down date
  var distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  // Display the result in the element with id="demo"
  document.getElementById("timer").innerHTML = "Time left " + hours + ":"
  + minutes + ":" + seconds;
  document.getElementById("timer2").innerHTML = "Time left " + hours + ":"
  + minutes + ":" + seconds;

  // If the count down is finished, write some text
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("thank_msg").style.display = "inline-block";
    document.getElementById("thank_msg2").style.display = "inline-block";
    document.getElementById("timer").style.display = "none";
    document.getElementById("timer2").style.display = "none";
    document.getElementById("solved_text").style.display = "none";
    document.getElementById("solved_text2").style.display = "none";
    document.getElementById("close_btn").style.display = "inline-block";  
    document.getElementById("close_btn2").style.display = "inline-block";    
    
  }
}, 1000);
